<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Detail Payment</v-card-title>
    <v-row v-if="isPaymentOrder">
      <span class="mr-2">Thanh toán order : {{ this.item.order.code }}</span>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="primary" text @click="onClose">Close</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
export default {
  name: 'DetailPayment',
  props: ['selectedItem', 'onClose'],
  data: () => ({
    item: {},
    isPaymentOrder: false
  }),
  watch: {
    selectedItem: {
      handler() {
        if (this.selectedItem.order_id) {
            this.isPaymentOrder = true;
        }
        if (this.selectedItem) this.item = this.selectedItem;
      },
      deep: true,
      immediate: true
    }
  }
}
</script>
