<template>
  <v-card>
    <v-card-title>Do you want to {{ is_approve ? 'approve' : 'reject' }} payment {{ idsToApprove ? 'these' : 'this' }} payment?</v-card-title>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processApprove">OK</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'ApprovePayment',
  props: ['idsToApprove', 'is_approve', 'onApprove', 'onCancel'],
  methods: {
    ...mapActions({
      approve: 'payment/approve'
    }),
    async processApprove() {
      let data = {
          is_approve: this.is_approve,
      }
      if (!this.idsToApprove) return this.noticeWarning('Item not found');
      this.approve({ data: data, id: this.idsToApprove}).then(()=>{
        this.onApprove();
      });
    }
  }
}
</script>
