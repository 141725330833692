<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create Withdraw Request</v-card-title>
    <v-row>
      <v-col cols="12">
        <v-text-field dense outlined label="Bank Name" v-model="item.bank_name"/>
      </v-col>
      <v-col cols="12">
        <v-text-field dense outlined label="Bank Number" v-model="item.bank_number"/>
      </v-col>
      <v-col cols="12">
        <v-text-field dense outlined label="Amount" v-model="item.amount"/>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processRequestWithdraw">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions} from 'vuex'

export default {
  name: 'WithdrawMoney',
  props: ['onRequestedWithdraw', 'onCancel'],
  data: () => ({
    item: {},
    code: '',
  }),
  methods: {
    ...mapActions({
      requestWithdraw: 'payment/requestWithdraw'
    }),
    processRequestWithdraw() {
      this.requestWithdraw(this.item).then(res => {
        if (res.status === 200 && res.data && res.data.error === false) {
          this.code = res.data.data ? res.data.data.code : '';
        }
        if (this.onRequestedWithdraw) this.onRequestedWithdraw(res.data.data);
      });
    }
  },
}
</script>
<style scoped>
h3 {
  margin-bottom: 1rem;
}
</style>
