<template>
  <v-card class="mx-auto pa-5">
    <v-card-title class="mx-0 px-0">Create New Discount</v-card-title>
    <v-row>
      <v-col cols="12">
        <v-autocomplete v-model="newItem.user_id" :items="users" dense item-text="email" item-value="id" label="Customer Email" outlined></v-autocomplete>
      </v-col>
      <v-col cols="12">
        <v-text-field dense outlined label="Amount" type="number" v-model="newItem.amount"/>
      </v-col>
      <v-col cols="12">
        <v-textarea dense outlined label="Description" type="text" v-model="newItem.description"></v-textarea>
      </v-col>
    </v-row>
    <v-card-actions class="mx-0 pa-0">
      <v-spacer></v-spacer>
      <v-btn color="secondary" text @click="onCancel">Cancel</v-btn>
      <v-btn color="primary" text @click="processCreateDiscount">Create</v-btn>
    </v-card-actions>
  </v-card>
</template>
<script>
import {mapActions, mapGetters} from 'vuex'
import CONSTANTS from '@/config/constants'

export default {
  name: 'Discount',
  props: ['onCreated', 'onCancel'],
  data: () => ({
    newItem: {
      user_id: 0,
      amount: 0,
      description: '',
      payment_type: CONSTANTS.PAYMENT_TYPE.DISCOUNT,
    }
  }),
  computed: {
    ...mapGetters({
      paymentTypes: 'payment/listPaymentType',
      users: 'user/all'
    })
  },
  methods: {
    ...mapActions({
      createItem: 'payment/create',
      getAllUser: 'user/all'
    }),
    processCreateDiscount() {
      this.createItem(this.newItem).then(() => {
        this.newItem = {
          user_id: 0,
          amount: 0,
          description: '',
          payment_type: CONSTANTS.PAYMENT_TYPE.DISCOUNT,
        }
        this.onCreated();
      });
    }
  },
  async mounted() {
    await this.getAllUser()
  }
}
</script>
